import { createGlobalStyle } from "styled-components";

/*
font-family: 'Source Sans Pro', sans-serif;
font-family: 'Ubuntu Mono', monospace;
*/

const GlobalStyle = createGlobalStyle`
*,*::before,*::after,h1,h2,h3,h4,h5,h6{
margin:0;
padding:0;


}

h1,h2,h3,h4,h5,h6{
display:inline-block;

}

html {
  width: 100%;
  height: 100%;
}

  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x:hidden;
    font-family: 'Source Sans Pro', sans-serif;
  }

  #root{
    width: 100%;
    height: 100%;
  }
`;

export default GlobalStyle;
